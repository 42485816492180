.cardcontainer {
  display: grid;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  min-height: 165px !important;
  border-radius: 10px;
  padding: 40px;
  gap: 20px !important;
}
.cardaddress {
  display: grid;
  position: relative;
  place-content: center;
  /* gap: 20px !important; */
}
.locationname {
  font-family: "Inter";
  font-weight: 950;
  font-size: 16px;
  line-height: 22px;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.bottomgap {
  padding-bottom: 20px;
}
.cardfinal {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cardfinal {
    display: block !important;
  }
}
