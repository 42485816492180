.fonticon {
  font-size: 50px;
  margin-bottom: 8px;
}
.icon-text-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
}

.icon-text-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
@media (max-width: 1024px) {
  .fonticon {
    font-size: 40px;
  }
  .icon-text-item {
    margin: 8px;
  }
}

@media (max-width: 767px) {
  .icon-text-row {
    gap: 10px; /* Adjust the gap between items as needed */
  }
  .fonticon {
    font-size: 30px;
  }
  .icon-text-item {
    margin: 5px;
  }
}
