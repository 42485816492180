.modal-banner {
  display: block;
  visibility: hidden;
}

.modal-show {
  visibility: visible;
  background-color: rgba(169, 169, 169, 0.8);
  transition: opacity 0.2s linear;
}

.modal-content-banner {
  opacity: 0;
}

.modal-show .modal-content-banner {
  opacity: 1;
  transition: 0.2s linear;
}
.modal-dialog {
  width: 60%;
  height: 60%;
}
@media screen and (max-width: 768px) {
  .modal-dialog {
    width: auto !important;
    height: auto !important;
  }
}
